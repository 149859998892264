import React from 'react';
import PropTypes from 'prop-types';
import { LazyImage, Link } from 'sana/elements';
import styles from './../ProductSubcategory.module.scss';
import classNames from 'classnames';
import { getImageScalingClass, useIsTouchDevice } from '../../utils';

const Image = ({ imagePath, imageAltText, data ,imageScalingOption }) => {
  const isTouchDevice = useIsTouchDevice();
  const ImageProps = {
    src: imagePath,
    alt: `Product category - ${imageAltText}`,
    draggable: 'false',
    visibleByDefault: true,
    className: styles.image,
    'aria-hidden':true,
  };

  if(!ImageProps.src)
  return;

  return (
    <div className={classNames(styles.imageWrapper, styles[getImageScalingClass(imageScalingOption)])} aria-hidden>
      {!isTouchDevice && data.link ? (
        <Link to={data.link.to} url={data.link.url} tabIndex={-1}>
          <LazyImage {...ImageProps} />
        </Link> 
      ) : (
        <LazyImage {...ImageProps} />
      )}
    </div>
  );
};

Image.propTypes = {
  imagePath: PropTypes.string,
  imageAltText: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,  
  imageScalingOption:PropTypes.number.isRequired,
};

export default React.memo(Image);
