export const NAVIGATION_DATA_REQUESTED =
  'PRODUCT_SUB_CATEGORY/NAVIGATION_DATA_REQUESTED';
export function requestNavigationData(navId ) {
  return {
    type: NAVIGATION_DATA_REQUESTED,
    payload: { navId },
  };
}

export const NAVIGATION_DATA_RECEIVED =
  'PRODUCT_SUB_CATEGORY/NAVIGATION_DATA_RECEIVED';
export const navigationDataReceived = (navigationData, navId) => ({
  type: NAVIGATION_DATA_RECEIVED,
  payload: { navigationData, navId },
});
