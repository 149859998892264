import { NAVIGATION_DATA_RECEIVED } from './actions';
import { VIEWER_CHANGED, LANGUAGE_CHANGED } from 'sana/events';

const initialState = {
  navigationData: {},
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case NAVIGATION_DATA_RECEIVED:
      const { navigationData, navId } = payload;
      return {
        navigationData: {
          ...state.navigationData,
          [navId]: navigationData,
        },
      };
    case VIEWER_CHANGED:
        return onViewerChanged(state);

    case LANGUAGE_CHANGED:
        return onLanguageChanged(state);
    default:
      return state;
  }
};

export const onViewerChanged = state => {
  return { ...state, navigationData: undefined };
};

export const onLanguageChanged = state => {
  if (!state.navigationData)
    return state;

  return {
    ...state,
    navigationData: Object.entries(state.navigationData).reduce((navigationData, [key, value]) => {
      navigationData[key] = { ...value, expired: true };
      return navigationData;
    }, {}),
  };
};

export default reducer;
