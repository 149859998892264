exports = module.exports = require("../../../../../SDK/Sana.Commerce.WebApp/ClientApp/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "", ""]);

// exports
exports.locals = {
	"isTouchDevice": "(pointer:coarse)",
	"isTouchScreen": "(pointer:fine) and (any-pointer:coarse) and (any-pointer:fine)"
};